import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×4\\@85% 1RM`}</p>
    <p>{`Shoulder Bridge 4×4`}</p>
    <p>{`then,`}</p>
    <p>{`20-Power Cleans (95/65) (RX+ 115/75)`}</p>
    <p>{`10-”wall-less” 2 for wall balls (20/14) (squat, throw ball in the air,
squat again before catching ball)`}</p>
    <p>{`20-Squat Cleans`}</p>
    <p>{`10-wall-less 2 for wall balls`}</p>
    <p>{`20-Thrusters`}</p>
    <p>{`10-wall-less 2 for wall balls`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      